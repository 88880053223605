<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px; margin-top: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <a-spin :spinning="spinning">
        <div v-if="config.type === 'add'">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="订单编号">
              <span v-for="(item, index) in codeList" :key="index">
                <span>{{ item }}</span>
                <span v-show="index < codeList.length - 1">、</span>
              </span>
            </a-form-model-item>
            <a-form-model-item label="支付方式">
              <span>线下支付</span>
            </a-form-model-item>
            <a-form-model-item label="支付金额">
              <span style="color: #fc8600">￥{{ payNum }}</span>
            </a-form-model-item>
            <a-form-model-item label="付款账户" prop="account">
              <a-input :maxLength="37" v-model="form.account" />
            </a-form-model-item>
            <a-form-model-item label="支付银行" prop="bank">
              <a-input :maxLength="30" v-model="form.bank" />
            </a-form-model-item>
            <a-form-model-item label="上传付款凭证" prop="payment">
              <span class="notice_text">
                支持格式：PNG、JPG或JPEG或PDF，单个文件不超10M
              </span>
              <UploadTag
                :config="uploadConfig"
                @getImageUrl="getPaymentUrl"
              ></UploadTag>
            </a-form-model-item>
            <a-form-model-item label="备注" prop="revisitResult">
              <a-textarea
                :maxLength="100"
                v-model="form.remarks"
                placeholder="请输入"
                @change="remarksOnchange"
                :auto-size="{ minRows: 8, maxRows: 8 }"
              />
              <span style="position: absolute; right: 10px; bottom: -10px"
                >已输入{{ detLength }}/100</span
              >
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { orderManageApi } from "@/api/orderManage.js";
import UploadTag from "../../components/uploadTag.vue";
export default {
  components: {
    UploadTag,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
          num: 0,
        };
      },
    },
  },
  data() {
    //校验银行账号
    let validatorAccount = (rule, value, callback) => {
      if (!value) {
        callback(new Error("付款账户不能为空"));
      } else if (!/^[1-9]\d{9,37}$/.test(value.replace(/\s*/g, "")) && value) {
        callback(new Error("付款账户格式不正确"));
      } else {
        callback();
      }
    };
    return {
      payVoucher: "",
      confirmLoading: false,
      codeList: [],
      payNum: null,
      spinning: false,
      detLength: 0,
      uploadConfig: {
        barrelType: "secret",
        uploadType: "default",
        showPreview: true, //是否展示预览图标
        showRemove: true, //是否展示删除图标
        size: 10, //限制文件大小
        limitControl: false, //是否限制照片墙数量
        fileList: [], //文件列表
        limitType: ["image/jpeg", "image/png", "image/jpg", "application/pdf"],
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 15 },
      form: {
        account: "",
        bank: "",
        payment: "",
        remarks: "",
      },

      rules: {
        payment: [
          { required: true, message: "付款凭证不能为空", trigger: "blur" },
        ],
        bank: [
          { required: true, message: "支付银行不能为空", trigger: "blur" },
        ],
        account: [
          {
            required: true,
            trigger: "blur",
            validator: validatorAccount,
          },
        ],
      },
    };
  },
  mounted() {
    // this.$nextTick(() => {
    this.getDetail();
    // });
  },

  watch: {
    ["form.account"](val) {
      this.$nextTick(() => {
        this.form.account = val.replace(/\D/g, "").replace(/....(?!$)/g, "$& ");
      });
    },
  },

  methods: {
    //获取上传付款凭证地址
    getPaymentUrl(val, fileList) {
      // this.form.payment = val;
      console.log("返回的啥呀");
      console.log(fileList);
      let temp = [];
      fileList.forEach((v) => {
        temp.push(v.name);
      });
      this.form.payment = temp.join(",");
      this.uploadConfig.fileList = fileList;
    },
    getDetail() {
      this.spinning = true;
      let params = {
        orderIdList: this.config.record,
      };
      orderManageApi.getDetail(params).then((res) => {
        if (res.success) {
          this.codeList = res.data.orderCodeList;
          this.payNum = res.data.payAmount;
          this.spinning = false;
        } else {
          this.$message.error(res.errorMsg);
          this.spinning = false;
          setTimeout(() => {
            this.$emit("handleCancel");
          }, 500);
        }
      });
    },
    remarksOnchange() {
      this.$nextTick(() => {
        this.detLength = this.form.remarks.length;
      });
    },

    handleCancel() {
      this.$emit("handleCancel");
    },
    //保存批量凭证详情
    addBatchPay() {
      this.confirmLoading = true;
      // let tempArr=[];
      let params = {
        orderCodeList: this.codeList,
        payAmount: this.payNum,
        orderIdList: this.config.record,
        payAccount: this.form.account.replace(/\s*/g, ""),
        payBank: this.form.bank,
        payRemark: this.form.remarks,
        payMethod: 1,
        payVoucher: this.form.payment,
      };
      orderManageApi.addBatchPay(params).then((res) => {
        if (res.success) {
          this.$emit("handleOk");
          this.confirmLoading = false;
        } else {
          this.$message.error(res.errorMsg);
          this.confirmLoading = false;
        }
      });
    },
    handleOk() {
      if (this.config.type === "add") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.addBatchPay();
          } else {
            return false;
          }
        });
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notice_text {
  color: rgba(0, 0, 0, 0.39);
  font-size: 12px;
}
.add_positon {
  position: relative;
}
.modal_content {
  display: flex;
}
.modal_left {
  min-width: 120px;
  text-align: right;
}
.modal_right {
  padding-bottom: 10px;
  flex-grow: 1;
}
.modal_text {
  text-align: left;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  img {
    margin-bottom: 4px;
  }
  .modal_logo {
    margin-left: 10px;
  }
}
.modal_stext {
  color: rgb(136, 131, 131);
  font-size: 12px;
  padding-left: 36px;
  font-weight: 200;

  margin-bottom: 20px;
}

.header_contain {
  display: flex;
  justify-content: space-between;
  /* margin-top: 20px; */
  padding: 0 40px;
  padding-top: 20px;
}

/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
.modal_reset {
  color: rgb(136, 131, 131);
  font-size: 14px;
  padding-left: 36px;
  font-weight: 200;
}
/deep/.ant-table-header {
  min-width: 0px !important;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
}
</style>