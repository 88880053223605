<template>
  <div>
    <div class="search_container">
      <div>
        <span>订单编号：</span>
        <a-input
          @keydown.native.enter="handleSearch"
          class="input_width"
          placeholder="请输入"
          v-model="orderCode"
        />
      </div>
      <div>
        <span>订购时间：</span>
        <a-date-picker
          v-model="orderTime"
          class="input_width"
          @change="onTimeChange"
        />
      </div>

      <div>
        <span>订单类型：</span>
        <a-select v-model="orderType" class="input_width">
          <a-select-option
            v-for="(item, index) in orderTypeList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <span>订购账号：</span>
        <SelectScroll
          :config="selectConfig"
          v-model="orderAccount"
        ></SelectScroll>
        <!-- <a-select v-model="orderAccount" class="input_width">
          <a-select-option
            v-for="(item, index) in orderAccountList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select> -->
      </div>
    </div>
    <div class="search_container add_button">
      <div>
        <span>订单状态：</span>
        <a-select v-model="orderStatus" class="input_width">
          <a-select-option
            v-for="(item, index) in orderStatusList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <span>订购租户：</span>
        <a-input
          @keydown.native.enter="handleSearch"
          class="input_width"
          placeholder="请输入"
          v-model="tenantName"
        />
      </div>
      <div class="blank_contain"></div>
      <!-- <div class="blank_contain"></div> -->
      <div class="blank_contain">
        <a-button @click="reset"> 重置 </a-button>
        <a-button
          style="margin-left: 20px"
          @click="handleSearch"
          type="primary"
        >
          查询
        </a-button>
      </div>
    </div>
    <div class="add_button">
      <a-button @click="passOrder" type="primary">审核通过 </a-button>
      <a-button style="margin-left: 20px" @click="uploadPay">
        上传付款凭证
      </a-button>
    </div>
    <div style="margin-top: 20px">
      <a-table
        :loading="loading"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        style="min-width: 1500px"
        :pagination="pagination"
        :rowKey="(record) => record.orderId"
        :columns="columns"
        :data-source="dataTable"
        :scroll="{ x: 1800 ,y:379}"
      >
        <span slot="orderStatus" slot-scope="text">
          <span style="color: #ff4d4f" v-if="text === 1">待付款</span>
          <span style="color: #faad14" v-if="text === 2">待审核</span>
          <span style="color: #52c41a" v-if="text === 3">已完成</span>
          <span style="color: #faad14" v-if="text === 5">资源初始化</span>
          <span style="color: #ff4d4f" v-if="text === 6">资源初始化失败</span>
          <span v-if="text === 4">已取消</span>
        </span>

        <span slot="orderMoney" slot-scope="text"
          ><span v-if="text"> ￥{{ text }} </span></span
        >
        <span slot="orderType" slot-scope="text">
          {{ orderTypeList[text].name }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="goDetail(record)">详情</a>
        </span>
      </a-table>
    </div>
    <ModelTag
      v-if="modelConfig.show"
      @handleCancel="handleCancel"
      @handleOk="handleOk"
      :config="modelConfig"
    ></ModelTag>
  </div>
</template>

<script>
import pagination from "@/mixins/Pagination";
import ModelTag from "./model.vue";
import { orderManageApi } from "@/api/orderManage.js";
import SelectScroll from "../../components/selectScroll.vue";

export default {
  mixins: [pagination],
  components: {
    ModelTag,
    SelectScroll,
  },
  data() {
    return {
      loading: false,
      selectConfig: {
        url: "/cloud-platform-backend-facade/front-api/v1/order/account",
        params: "userName",
        label: "accountName",
        value: "accountId",
        width: "240px",
        pass: true,
        placeholder: "请输入",
      },
      orderCode: "",
      orderTime: null,
      tenantName: "",
      orderType: 0,
      orderAccount: null,
      orderStatus: 1,
      selectedRowKeys: [],
      selectRow: [],
      modelConfig: {
        type: "add",
        id: "",
        show: false,
        record: null,
        title: "人员班组",
        width: "60%",
        okText: "保存",
        delArr: [],
        status: false,
        num: 0,
      },
      orderTypeList: [
        {
          name: "全部",
          value: 0,
        },
        {
          name: "应用订单",
          value: 1,
        },
        {
          name: "解决方案订单",
          value: 2,
        },
        {
          name: "osLite",
          value: 3,
        },
      ],
      orderAccountList: [
        {
          name: "全部",
          value: 0,
        },
        {
          name: "普通用户",
          value: 1,
        },
        {
          name: "企业用户",
          value: 2,
        },
        {
          name: "开发者用户",
          value: 3,
        },
      ],
      orderStatusList: [
        {
          name: "全部",
          value: "",
        },
        {
          name: "待付款",
          value: 1,
        },
        {
          name: "待审核",
          value: 2,
        },
        {
          name: "订单完成",
          value: 3,
        },
        {
          name: "已取消",
          value: 4,
        },
         {
          name: "资源初始化",
          value: 5,
        },
        {
          name: "资源初始化失败",
          value: 6,
        },
      ],
      columns: [
        {
          title: "订单编号",
          dataIndex: "orderCode",
          key: "orderCode",
          align: "center",
        },

        {
          title: "订单金额",
          dataIndex: "orderPrice",
          key: "orderPrice",
          align: "center",
          scopedSlots: { customRender: "orderMoney" },
          // width: "110px",
        },
        {
          title: "订单类型",
          dataIndex: "orderType",
          key: "orderType",
          align: "center",
          scopedSlots: { customRender: "orderType" },
          // width: "110px",
        },

        {
          title: "订单状态",
          dataIndex: "orderStatus",
          key: "orderStatus",
          align: "center",
          scopedSlots: { customRender: "orderStatus" },
          // width: "160px",
        },
        {
          ellipsis: true,
          title: "订购租户",
          dataIndex: "tenantName",
          key: "tenantName",
          align: "center",
          // scopedSlots: { customRender: "orderStatus" },
          // width: "160px",
        },

        {
          ellipsis: true,
          title: "订购信息",
          dataIndex: "orderInfo",
          key: "orderInfo",
          align: "center",
          scopedSlots: { customRender: "orderInfo" },
          width: "260px",
        },
        {
          title: "订购账号",
          dataIndex: "orderAccount",
          key: "orderAccount",
          align: "center",
          scopedSlots: { customRender: "orderAccout" },
          // width: "160px",
        },

        {
          title: "订购时间",
          dataIndex: "orderTime",
          key: "orderTime",
          align: "center",
          scopedSlots: { customRender: "orderTime" },
          // width: "160px",
        },

        {
          title: "操作",
          align: "center",
          key: "action",
          scopedSlots: { customRender: "action" },
          fixed: 'right',
          width:160
        },
      ],
      dataTable: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    goDetail(val) {
      this.$router.push({ name: "orderDetail", params: { id: val.orderId } });
    },
    uploadPay() {
      if (this.selectedRowKeys.length > 0) {
        let params = {
          orderIdList: this.selectedRowKeys,
        };
        orderManageApi.getDetail(params).then((res) => {
          if (res.success) {
            this.openModel("add", this.selectedRowKeys);
          } else {
            this.$message.error(res.errorMsg);
          }
        });
      } else {
        this.$message.warn("请先选中订单");
      }
      // let num = 0;
      // let tempArr = this.selectRow.filter((v) => {
      //   return v.orderStatus === 1;
      // });
      // tempArr.map((v) => {
      //   num += Number(v.orderMoney);
      // });
      // if (tempArr.length > 0) {
      //   this.openModel("add", tempArr, num);
      // } else {
      //   this.$message.warn("所选订单中无待付款订单");
      // }
    },
    onSelectChange(selectedRowKeys, selectRow) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectRow = selectRow;
    },
    //时间修改事件
    onTimeChange(dates, dateStrings) {
      this.orderTime = dateStrings;
    },

    //重置
    reset() {
      this.orderCode = "";
      this.orderTime = "";
      this.selectedRowKeys = [];
      this.selectRow = [];
      this.orderType = 0;
      this.orderStatus = 1;
      this.tenantName = "";
      this.orderAccount = undefined;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getData();
    },
    //查询数据接口
    getData() {
      this.loading = true;
      let params = {
        orderCode: this.orderCode,
        orderDate: this.orderTime,
        orderType: this.orderType,
        orderStatus: this.orderStatus,
        tenantName: this.tenantName,
        userId: this.orderAccount,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      orderManageApi.getTableData(params).then((res) => {
        if (res.success) {
          this.pagination.total = Number(res.data.total);
          this.dataTable = res.data.records;
          this.loading = false;
        } else {
          this.$message.error(res.errorMsg);
          this.loading = false;
        }
      });
    },
    passOrder() {
      if (this.selectedRowKeys.length > 0) {
        this.passOrderApi(this.selectedRowKeys);
      } else {
        this.$message.warn("请先选中订单");
      }
    },
    //订单审核接口
    passOrderApi(idArr) {
      let params = {
        orderIdList: idArr,
      };
      orderManageApi.passOrder(params).then((res) => {
        if (res.success) {
          this.$message.success("操作成功！");
          this.selectedRowKeys = [];
          this.selectRow = [];
          this.getData();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    //查询
    handleSearch() {
      this.pagination.current = 1;
      this.selectedRowKeys = [];
      this.selectRow = [];
      this.getData();
    },

    //调用弹框事件
    openModel(type, record) {
      this.modelConfig.type = type;
      this.modelConfig.show = true;
      if (type === "add") {
        this.modelConfig.record = record;
        this.modelConfig.title = "上传付款凭证";
        this.modelConfig.okText = "确定";
        this.modelConfig.width = "1000px";
      } else {
        return;
      }
    },

    //弹框关闭
    handleCancel() {
      this.modelConfig.show = false;
    },
    //弹框保存按钮
    handleOk() {
      this.modelConfig.show = false;
      this.$message.success("保存成功！");
      this.selectedRowKeys = [];
      this.selectRow = [];
      this.getData();
    },
    // 分页改变
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getData();
    },
    //分页
    onPage(current) {
      this.pagination.current = current;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.blank_contain {
  width: 310px;
  text-align: right;
}
.input_width {
  width: 240px;
}
.search_container {
  display: flex;
  justify-content: space-between;
  min-width: 1500px;
  overflow: auto;
}
.add_button {
  margin-top: 20px;
  margin-bottom: 20px;
}
.popover_contain {
  height: 30px;
  width: 120px;
  // background-color: aqua;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}
.popover_contain:hover {
  background-color: #f3f3f3;
}
// /deep/.ant-table-body {
//   overflow-x: hidden;
//   &::-webkit-scrollbar {
//     /*滚动条整体样式*/
//     width: 4px;
//     height: 5px;
//   }

//   &::-webkit-scrollbar-thumb {
//     /*滚动条里面的滑块*/
//     border-radius: 5px;
//     background: rgba(62, 63, 65, 0.5);
//   }

//   &::-webkit-scrollbar-track {
//     /*滚动条里面轨道背景*/
//     // display: none;
//     width: 4px;
//     padding: 0px;
//     border-radius: 4px;
//     background: rgba(0, 0, 0, 0.1);
//   }
// }

// /deep/.ant-table-header {
//   min-width: 0px !important;
//   &::-webkit-scrollbar {
//     /*滚动条整体样式*/
//     width: 4px;
//   }
// }
  /deep/.ant-table {
    min-height: 379px;
    }
</style>