import axios from "@/config/http";

export const orderManageApi = {
  // 获取订单管理table数据
  getTableData(params) {
    return axios.get("/cloud-platform-backend-facade/front-api/v1/order", {
      params,
    });
  },
  //重新初始化
  reload(params) {
    return axios.get("/cloud-platform-backend-facade/front-api/v1/order/reinitializeResource", {
      params,
    });
  },
  //审核订单
  passOrder(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/order/review",
      params
    );
  },

  //获取批量凭证详情
  getDetail(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/order/batchPaymentCertificate",
      params
    );
  },
  //保存批量凭证详情
  addBatchPay(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/order/paymentCertificate",
      params
    );
  },

  //获取批量凭证详情
  getInnerDetail(params) {
    return axios.get("/cloud-platform-backend-facade/front-api/v1/order/detail", {
      params,
    });
  },
  //修改订单详情保存
  changeOrder(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/order/orderInfo",
      params
    );
  },

  //编辑订单详情保存
  editOrder(params) {
    return axios.put("/cloud-platform-backend-facade/front-api/v1/order", params);
  },
};
